export default {
  install: (app) => {
    app.config.globalProperties.$dateFormatter = (date) => {
      const dateObject = new Date(date);

      const year = dateObject.getFullYear();
      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0');

      return `${day}/${month}/${year}`;
    };
  },
};
