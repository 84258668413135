import { createApp } from 'vue';

// Application bootstrapper component
import AppRoot from '@/components/AppRoot.vue';

// Modules
import '@/common/app-version-log.js';
import '@/api/client.js';
import ActionCableVue from 'actioncable-vue';
import { store } from '@/store/index.js';
import { i18n } from '@/localization/index.js';
import router from '@/router/index.js';
import '@/common/localstorage_listener.js';
import '@/common/view_port.js';
import localizeErrorMessage from '@/common/localizeErrorMessage.js';
import dateFormatter from '@/common/dateFormatter.js';
import { focus, visible } from '@/directives/directives.js';
import declareGlobalComponents from '@/common/declarationComponents.js';
import UiToasterPlugin from '@/common/ui-toaster-plugin.js';
import adminCompanyLinkModifier from '@/common/admin-company-link-modifier.js';
import { vMaska } from 'maska';
import i18nTimeDuration from './common/i18nTimeDuration.js';
import i18nTranslationExist from './common/i18nTranslationExist.js';
import { setupSentry } from './common/setup-sentry.js';
import { initDataService } from './services/init-data-service.js';

// Styles
import '@bi-book/bibook-ui-kit/dist/main.css';
import '@bi-book/bibook-ui-kit/src/assets/main.scss';
import '@/assets/stylesheet/application.scss';

async function initApp() {
  const initData = await initDataService.getData();

  // App
  const app = createApp(AppRoot);

  setupSentry(app, initData.data.app_domain);

  app.use(declareGlobalComponents);
  app.use(store);
  app.use(router);
  app.use(ActionCableVue, {
    debug: true,
    debugLevel: 'error',
    connectionUrl: '/api/cable',
    connectImmediately: true,
  });
  app.use(i18n);
  app.use(i18nTimeDuration);
  app.use(i18nTranslationExist);
  app.use(localizeErrorMessage);
  app.use(dateFormatter);
  app.use(UiToasterPlugin);
  app.use(adminCompanyLinkModifier);

  // directives
  app.directive('maska', vMaska);
  app.directive('focus', focus);
  app.directive('visible', visible);

  app.mount('#app');
}

initApp();
