import ApiUser from '@/api/user.js';
import { store } from '@/store/index.js';

export class SessionCheckService {
  constructor() {
    this.checkRequest = null;
  }

  checkSession() {
    if (this.checkRequest) {
      return this.checkRequest;
    }

    this.checkRequest = this.handleCheckSession();
    return this.checkRequest;
  }

  async handleCheckSession() {
    const response = await ApiUser.fetchInit();

    if (!response.data.current_company) {
      try {
        await store.dispatch('logout', false);
      } catch (error) {
        // do nothing
      }
      window.location.href = '/login';
    }

    this.checkRequest = null;
  }
}

const sessionCheckService = new SessionCheckService();
export default sessionCheckService;
