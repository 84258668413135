export default function i18nTranslationExist(app) {
  app.mixin({
    computed: {
      $te() {
        return (key) => {
          return key !== this.$t(key);
        };
      },
    },
  });
}
