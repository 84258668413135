export default {
  install: (app) => {
    app.config.globalProperties.$adminCompanyLinkModifier = function $adminCompanyLinkModifier(
      routerLink,
    ) {
      if (this.$store.getters.isAdminHost) {
        return {
          ...routerLink,
          name: `Admin${routerLink.name}`,
          params: {
            company_id: this.$route.params.company_id,
            ...routerLink.params,
          },
        };
      }
      return routerLink;
    };
  },
};
