<template lang="pug">
.app(v-if="appReady")
  router-view
UiToastProvider
ThemeGenerator(
  v-if="currentCompany?.ui_theme && !isAdminHost"
  :colors="currentCompany.ui_theme.colors"
)
</template>

<script>
import { mapGetters } from 'vuex';
import ThemeGenerator from '@/components/ThemeGenerator/ThemeGenerator.vue';
import { UiToastProvider } from '@bi-book/bibook-ui-kit';

export default {
  name: 'App',
  components: {
    UiToastProvider,
    ThemeGenerator,
  },
  computed: {
    ...mapGetters(['appReady', 'isAdminHost']),
    ...mapGetters('company', ['currentCompany']),
  },
};
</script>
