export default function i18nTimeDuration(app) {
  app.mixin({
    computed: {
      $duration() {
        /**
         * @param {number | string} duration - The duration in seconds.
         */
        return (duration, precise) => {
          let d = duration;
          if (!precise) {
            d = Math.round(duration);
          }

          const milliseconds = Math.floor((d * 1000) % 1000);
          if (!duration || duration === '0') {
            return `0 ${this.$t('duration.seconds', 0)}`
          }
          const seconds = Math.floor(d % 60);
          const minutes = Math.floor((d % 3600) / 60);
          const hours = Math.floor((d % (3600 * 24)) / 3600);
          const days = Math.floor(d / (3600 * 24));

          const result = [];

          if (days) result.push(`${days} ${this.$t('duration.days', days)}`);
          if (hours) result.push(`${hours} ${this.$t('duration.hours', hours)}`);
          if (minutes) result.push(`${minutes} ${this.$t('duration.minutes', minutes)}`);
          if (seconds) result.push(`${seconds} ${this.$t('duration.seconds', seconds)}`);
          if (milliseconds && precise) {
            result.push(`${milliseconds} ${this.$t('duration.milliseconds', milliseconds)}`);
          }

          return result.join(' ');
        };
      },
    },
  });
}
