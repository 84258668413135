export const focus = {
  mounted: (el) => el.querySelector('input, textarea, button, a').focus(),
};

export const visible = {
  mounted: (el, binding) => {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
  },
  updated: (el, binding) => {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
  },
};
