import RedirectService from '@/common/redirect-service.js';
import axios from 'axios';
import { store } from '@/store/index.js';
import sessionCheckService from '@/common/session-check-service';

const AUTH_PATHS = {
  google: '/auth/google_oauth2',
  azure: '/auth/azure_oauth2',
  saml: '/auth/saml',
  oidc: '/auth/oidc',
  email: '/login',
};

/*
  session_invalid -  view_as token from not current company(maybe not real case)
  need_authorization - session expired (NOT email login)
  user_signed_out:
    1) the user not added in the company or was deleted during surfing
    2) the user was inactive for a long time
    3) the session expired (email login)
 */

export function onResponseSuccess(response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data

  if (response.data?.meta?.redirect_uri) {
    window.location.href = response.data.meta.redirect_uri;
    return response;
  }

  if (response?.data?.message === 'need_authorization') {
    const authPath = AUTH_PATHS[response.data?.source] ?? AUTH_PATHS.email;
    if (authPath === AUTH_PATHS.email) {
      // not real case
      RedirectService.toLogin('require_authorization');
    } else {
      const redirectUrl = new URL(authPath, window.location.href);
      window.location.href = redirectUrl.href;
    }
    return Promise.reject();
  }

  if (response?.data?.message === 'user_signed_out') {
    RedirectService.toLogin('require_authorization');
    return Promise.reject();
  }

  return response;
}

export async function onResponseError(error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  if ([403, 401].includes(error.response?.status) && store.getters.isAuthenticated) {
    await sessionCheckService.checkSession();
  }

  if (error.response?.data?.error === 'session_invalid') {
    await store.dispatch('logout', false);
    return Promise.reject(RedirectService.toLogin('session_invalid'));
  }

  return Promise.reject(error);
}

axios.interceptors.response.use(onResponseSuccess, onResponseError);
