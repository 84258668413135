/**
 * LocalStorage listener
 */
window.addEventListener('storage', (e) => {
  console.log('StorageKey:', e.key);
  console.log('StorageValue:', e.newValue);

  /**
   * Reload other tabs when company changed
   */
  if (e.key === 'current-company') {
    window.location.reload();
  }
});
